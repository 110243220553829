/**
 * @file application.js
 */

(function(window, document, $, undefined){
	require('./_libs/jquery.wizvalidator');

	require('./_initialize/_navigation')();
	require('./_initialize/_accordion')();
	require('./_initialize/_top-tabContents')();
	require('./_initialize/_areaHight')();
	require('./_initialize/_smoothScroll')();
	require('./_initialize/_assessmentForm')();
	require('./_initialize/_social')();

})(window, document, jQuery);
