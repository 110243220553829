/**
 * @file _initialize/_assessmentForm.js
 */
(function ($) {
	'use strict';
		var ApplyClass = require('../_util/_apply-class');
		var AssessmentForm = require('../_view/_assessmentForm');

	/**
	 * recruit formのaccordion 初期化
	 * @return {undefined}
	 */


	var AssessmentFormInitialize = function () {
		$(function () {
			applyClass();
		});
	};

	var applyClass = function (idx, e) {
		$('.assessment__form').each(function() {
			var $e = $(e);
			ApplyClass.apply(AssessmentForm, $e);
		});
	};

	module.exports = AssessmentFormInitialize;
})(jQuery);
