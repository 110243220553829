/**
 * @file _view/_assessmentForm.js
 */

(function ($) {
	'use strict';
	var common = require('../_common/_common.js');
	var wizvalidator = require('../_common/_wizvalidator.js');
	var Common = new common();
	var WizValidator = new wizvalidator();

	/**
	 * @namespace AssessmentForm
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var AssessmentForm = function () {
		AssessmentForm.init();
		AssessmentForm.addProduct();
		AssessmentForm.removeProduct();
		AssessmentForm.sendForm();
	};

	window.mogland.const.VALIDATOR_OPTIONS = {
		errorClassName		: 'error',
		parentClassName		: 'assessment__form__group',
		sysErrorAlert		: false,
		useParentError		: true,
		specialCharCheck	: false,
		showAsPopup			: true,
		popupClassName		: 'validateMsgBox',
		popupColor			: '#fc0000',
		popupTextColor		: '#fff'
	};

	AssessmentForm.init = function () {
		var count = 0;
		$('form').find('input.validate:visible').each(function () {
			if($(this).val() == ''){
				count++
			}
		});
		$('span.count-no').text(count + '項目')
		$('input.validate:visible').blur(function () {
			count--;
			if($(this).hasClass('error')){
				count++;
			}
			if(count == 0){
				$('.no-send-button').addClass('hidden');
				$('.send-button').removeClass('hidden');
				$('.count-area').slideUp();
			}
			$('span.count-no').text(count + '項目')
		});
	};

	AssessmentForm.addProduct = function(){
		$('.assessment__product--add-button').on('click', function () {
			$('.add-area .assessment__area:hidden:first').slideDown();
			if($('.assessment__area:visible').length >= 5){
				$('.assessment__product--add').slideUp();
			}
			if($('.count-no').html() == '0項目'){
				$('.no-send-button').removeClass('hidden');
				$('.send-button').addClass('hidden');
				$('.count-area').slideDown();
			}
			AssessmentForm.init();
		});
	};

	AssessmentForm.removeProduct = function(){
		$('.add-area').on('click', '.assessment__product--remove-button', function () {
			$(this).closest('.assessment__area').slideUp();
			if($('.count-no').html() != '0項目'){
				$('.no-send-button').removeClass('hidden');
				$('.send-button').addClass('hidden');
				$('.count-area').slideDown();
			// }else{
			// 	$('.no-send-button').addClass('hidden');
			// 	$('.send-button').removeClass('hidden');
			// 	$('.count-area').slideUp();
			}
			$('.assessment__product--add').slideDown();
			AssessmentForm.init();
		});
	};

	AssessmentForm.sendForm = function () {
		$('.send-button').on('click',function (e) {
			e.preventDefault();
			var form = $('.wpcf7-form')
			form.find('.validate:visible').each(function () {
				$(this).WizValidator(window.mogland.const.VALIDATOR_OPTIONS);
			});
			if (form.find('.validate:visible').hasClass('error')) {
				alert('入力項目が不正です。');
				return;
			} else {
				// 商品1つめ
				var productName = $('#product-name').val();
				var productStatus = $("input[name='product-status']:checked").val();
				var productModel = $("#product-model").val();
				var productEtc = $("#product-etc").val();
				// 商品２つめ
				var productName2 = $('#product-name2').val();
				var productStatus2 = $("input[name='product-status2']:checked").val();
				var productModel2 = $("#product-model2").val();
				var productEtc2 = $("#product-etc2").val();
				// 商品３つめ
				var productName3 = $('#product-name3').val();
				var productStatus3 = $("input[name='product-status3']:checked").val();
				var productModel3 = $("#product-model3").val();
				var productEtc3 = $("#product-etc3").val();
				// 商品４つめ
				var productName4 = $('#product-name4').val();
				var productStatus4 = $("input[name='product-status4']:checked").val();
				var productModel4 = $("#product-model4").val();
				var productEtc4 = $("#product-etc4").val();
				// 商品５つめ
				var productName5 = $('#product-name5').val();
				var productStatus5 = $("input[name='product-status5']:checked").val();
				var productModel5 = $("#product-model5").val();
				var productEtc5 = $("#product-etc5").val();
				var product = '査定依頼品'  + '\n'
							+ '商品名: ' + productName + '\n'
							+ '状態: ' + productStatus + '\n'
							+ '型番: ' + productModel + '\n'
							+ '備考: ' + productEtc + '\n\n'
							+ '査定依頼品２'  + '\n'
							+ '商品名: ' + productName2 + '\n'
							+ '状態: ' + productStatus2 + '\n'
							+ '型番: ' + productModel2 + '\n'
							+ '備考: ' + productEtc2 + '\n\n'
							+ '査定依頼品３'  + '\n'
							+ '商品名: ' + productName3 + '\n'
							+ '状態: ' + productStatus3 + '\n'
							+ '型番: ' + productModel3 + '\n'
							+ '備考: ' + productEtc3 + '\n\n'
							+ '査定依頼品４'  + '\n'
							+ '商品名: ' + productName4 + '\n'
							+ '状態: ' + productStatus4 + '\n'
							+ '型番: ' + productModel4 + '\n'
							+ '備考: ' + productEtc4 + '\n\n'
							+ '査定依頼品５'  + '\n'
							+ '商品名: ' + productName5 + '\n'
							+ '状態: ' + productStatus5 + '\n'
							+ '型番: ' + productModel5 + '\n'
							+ '備考: ' + productEtc5 + '\n\n'
				$('#product').text(product);
				if ($('#mailmagazine').prop('checked')) {
					$('#mailmagazineCheck').val('受け取る');
				}
				setTimeout(function(){
					form.submit();
				},1000);
				setTimeout(function(){
					window.location.href = '/assessment-complete';
				},1010);
			}
		});
	};
	/**
	 * @memberOf AssessmentForm
	 * @const {string}
	 */
	AssessmentForm._classname = 'AssessmentForm';

	module.exports = AssessmentForm;
})(jQuery);
