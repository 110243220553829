/**
 * @file _view/_facebook.js
 */
(function ($) {
	'use strict';

	/**
	 * @namespace Facebook
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var Facebook = function () {
		Facebook.init();
		Facebook.resize();
	};

	Facebook.init = function () {
		// ページプラグインを追加する要素
		var facebookWrap = $('#facebook-wrapper');
		var fbBeforeWidth = '';
		var fbWidth = facebookWrap.innerWidth();
		var fbTimer = false;
		var pagePluginCode = '<div class="fb-page" data-href="https://www.facebook.com/mogland/" data-tabs="timeline" data-width="'+fbWidth+'" data-height="220" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/mogland/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/mogland/"></a></blockquote></div>';
		if (fbTimer !== false) {
			clearTimeout(fbTimer);
		}
		fbTimer = setTimeout(function() {
			fbWidth = facebookWrap.width();
			if(fbWidth != fbBeforeWidth) {
				facebookWrap.html(pagePluginCode);
				window.FB.XFBML.parse();
				fbBeforeWidth = fbWidth;
			}
		}, 200);
	}

	Facebook.resize = function(){
		$(window).resize(function() {
			Facebook.init();
		});
	}

	/**
	 * @memberOf Facebook
	 * @const {string}
	 */
	Facebook._classname = 'Facebook';

	module.exports = Facebook;
})(jQuery);
