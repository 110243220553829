/**
 * @file _wizvalidator.js
 */
(function(window, document, $, undefined){
	"use strict";

	window.mogland = window.mogland || {};
	window.mogland.const = window.mogland.const || {};

	/**
	 * 初期化
	 *
	 * @memberof WizValidator
	 * @return {undefined}
	 */
	var WizValidator = function(){
		this.activateValidator();
		this.setLeftMargin();
	};
	/**
	 * @memberof WizValidator
	 * @const {string}
	 */
	WizValidator._classname = 'WizValidator';

	/**
	 * ポップアップにmargin-leftを設定
	 *
	 * @memberof WizValidator
	 * @return {undefined}
	 */
	WizValidator.prototype.setLeftMargin = function(){
		$('.validateMsgBox').addClass('ml120-md ml120-lg');
	};

	/**
	 * バリデーターのactivate
	 *
	 * @memberof WizValidator
	 * @return {undefined}
	 */
	WizValidator.prototype.activateValidator = function(){
		$(document).on('blur','input.validate',function(){
			$(this).WizValidator(window.mogland.const.VALIDATOR_OPTIONS);
		});
	};

	module.exports = WizValidator;
})(window, document, jQuery);
