/**
 * @file _view/_smoothScroll.js
 */
(function ($) {
	'use strict';

	/**
	 * @namespace SmoothScroll
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var SmoothScroll = function () {
		SmoothScroll.scroll();
	};

	// スムーズに動く
	SmoothScroll.scroll = function(){
		$('.list--archives a').click(function () {
			var href = $(this).attr('href');
			var position = $(href).offset().top;
			$('body,html').animate({
				scrollTop: position
			}, 500);
			return false;
		});
	}

	/**
	 * @memberOf SmoothScroll
	 * @const {string}
	 */
	SmoothScroll._classname = 'SmoothScroll';

	module.exports = SmoothScroll;
})(jQuery);
