/**
 * @file _view/_tabContents.js
 */
(function ($) {
	'use strict';

	/**
	 * @namespace TabContents
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var TabContents = function () {
		TabContents.click();
		$('.step-purchase__contents.pc').not('#type-1').hide();
	};

	// contents切替時に表示を切り替える
	TabContents.click = function(){
		$('.method-purchase .nav-tabs li a').click(function(){
			var target = $(this).attr('href');
			switch (target) {
				case '#method-delivery':
					$('.step-purchase__contents.pc').not('#type-1').hide();
					$('.step-purchase__contents.pc#type-1').show();
					break;
				case '#method-official':
					$('.step-purchase__contents.pc').not('#type-2').hide();
					$('.step-purchase__contents.pc#type-2').show();
					break;
				case '#method-store':
					$('.step-purchase__contents.pc').not('#type-3').hide();
					$('.step-purchase__contents.pc#type-3').show();
					break;
			}
		});
	}

	/**
	 * @memberOf TabContents
	 * @const {string}
	 */
	TabContents._classname = 'TabContents';

	module.exports = TabContents;
})(jQuery);
