/**
 * @file _view/_areaHight.js
 */

(function ($) {
	'use strict';
	var common = require('../_common/_common.js');
	var Common = new common();

	/**
	 * @namespace AreaHight
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var AreaHight = function () {
		AreaHight.resultList();
		AreaHight.rankingList();
		AreaHight.resize();
	};

	AreaHight.resultList = function () {
		var setPrt = $('.list--results');
		var setChd = setPrt.find('.results__contents>a');
		var prtWidth = setPrt.outerWidth();
		var chdWidth = setChd.outerWidth();
		var setNum = Math.floor(prtWidth / chdWidth);
		var chdLength = setChd.length;
		setPrt.each(function(){
			var h = 0;
			setChd.each(function(i){
				var self = $(this),
				i = i+1,
				hSet = self.outerHeight(),
				pdTop = parseInt(self.css('padding-top')),
				pdBtm = parseInt(self.css('padding-bottom')),
				boxSizing = self.css('box-sizing');
				self.addClass('heightReplace');
				if(hSet > h){
					h = hSet;
				};
				if(boxSizing === 'border-box'){
					setPrt.find('.heightReplace').css({height:h});
				} else {
					setPrt.find('.heightReplace').css({height:(h-(pdTop + pdBtm))});
				}
				if(i%setNum == 0 || i == chdLength){
					h = 0;
					setChd.removeClass('heightReplace');
				}
			});
		});
	};

	AreaHight.rankingList = function () {
		var setPrt = $('.list--ranking');
		var setChd = setPrt.find('.list__item>a');
		var prtWidth = setPrt.outerWidth();
		var chdWidth = setChd.outerWidth();
		var setNum = Math.floor(prtWidth / chdWidth);
		var chdLength = setChd.length;
		setPrt.each(function(){
			var h = 0;
			setChd.each(function(i){
				var self = $(this),
				i = i+1,
				hSet = self.outerHeight(),
				pdTop = parseInt(self.css('padding-top')),
				pdBtm = parseInt(self.css('padding-bottom')),
				boxSizing = self.css('box-sizing');
				self.addClass('heightReplace');
				if(hSet > h){
					h = hSet;
				};
				if(boxSizing === 'border-box'){
					setPrt.find('.heightReplace').css({height:h});
				} else {
					setPrt.find('.heightReplace').css({height:(h-(pdTop + pdBtm))});
				}
				if(i%setNum == 0 || i == chdLength){
					h = 0;
					setChd.removeClass('heightReplace');
				}
			});
		});
	};

	AreaHight.resize = function () {
		$(window).on('resize',function(){
			AreaHight.resultList();
		}).resize();
	};

	module.exports = AreaHight;
})(jQuery);
