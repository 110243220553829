/**
 * @file _view/_navigation.js
 */
(function ($) {
	'use strict';

	/**
	 * @namespace Navigation
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var Navigation = function () {
		Navigation.SnavOpen();
		Navigation.MenuOpen();
		Navigation.Resize();
	};

	// hover時に画像を切替える
	Navigation.SnavOpen = function(){
		$('.list--gnav .list__item a').hover(function(){
			$(this).children('img').attr('src', $(this).children('img').attr('src').replace('_off', '_on'));
		}, function(){
			$(this).children('img').attr('src', $(this).children('img').attr('src').replace('_on', '_off'));
		});
	}

	Navigation.MenuOpen = function(){
		$('.sp-menu-open').click(function(){
			$('.list--gnav').toggleClass('open');
		});
		$('.snav-open').click(function(){
			$('.list--snav').toggleClass('open');
		})
	}

	Navigation.Resize = function(){
		$(window).resize(function() {
			var width = $(window).width();
			if(width >= 992){
				$('.list--gnav').removeClass('open');
			}else{
				return;
			}
		});
	}

	/**
	 * @memberOf Navigation
	 * @const {string}
	 */
	Navigation._classname = 'Navigation';

	module.exports = Navigation;
})(jQuery);
