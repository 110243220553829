/**
 * @file _common/_common.js
 */
(function ($) {
	'use strict';

	var Common = function () {
	};

	/**
	 * @memberof Common
	 * @const {string}
	 */
	Common.prototype._classname = 'Common';

	/**
	 * デバイス判定
	 *
	 * @memberof Common
	 * @return {undefined}
	 */
	Common.prototype.device = function () {
		var userAgent = window.navigator.userAgent.toLowerCase();

		if (userAgent.indexOf('iphone') > 0 || userAgent.indexOf('ipad') > 0 || userAgent.indexOf('android') > 0) {
			$('html').addClass('sp');
		} else {
			$('html').addClass('pc');
		}
	}

	module.exports = Common;
})(jQuery);
