/**
 * @file _initialize/_areaHight.js
 */
(function ($) {
	'use strict';
		var ApplyClass = require('../_util/_apply-class');
		var AreaHight = require('../_view/_areaHight');

	/**
	 * recruit formのaccordion 初期化
	 * @return {undefined}
	 */


	var AreaHightInitialize = function () {
		$(window).load(function() {
			loadClass();
		});
	};

	/**
	 * Recruit Form の各項目をアコーディオンさせる
	 */
	var loadClass = function (idx, e) {
		$('.results').each(function() {
			var $e = $(e);
			ApplyClass.apply(AreaHight, $e);
		});
	};

	module.exports = AreaHightInitialize;
})(jQuery);
