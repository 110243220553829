/**
 * @file _initialize/_accordion.js
 */
(function ($) {
	'use strict';
		var ApplyClass = require('../_util/_apply-class');
		var facebook = require('../_view/_facebook');

	/**
	 * recruit formのaccordion 初期化
	 * @return {undefined}
	 */


	var SocialInitialize = function () {
		$(function () {
			applyClass();
		});
	};

	/**
	 * Recruit Form の各項目をアコーディオンさせる
	 */
	var applyClass = function (idx, e) {
		$('#facebook').each(function() {
			var $e = $(e);
			ApplyClass.apply(facebook, $e);
		});
	};

	module.exports = SocialInitialize;
})(jQuery);
