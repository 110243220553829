/**
 * @file _initialize/_navigation.js
 */
(function ($) {
	'use strict';
		var ApplyClass = require('../_util/_apply-class');
		var Navigation = require('../_view/_navigation');

	/**
	 * recruit formのaccordion 初期化
	 * @return {undefined}
	 */


	var NavigationInitialize = function () {
		$(function () {
			applyClass();
		});
	};

	/**
	 * Recruit Form の各項目をアコーディオンさせる
	 */
	var applyClass = function (idx, e) {
		$('.navigation').each(function() {
			var $e = $(e);
			ApplyClass.apply(Navigation, $e);
		});
	};

	module.exports = NavigationInitialize;
})(jQuery);
