/**
 * @file _view/_accordion.js
 */
(function ($) {
	'use strict';

	/**
	 * @namespace Accordion
	 * @constructor
	 * @classdesc sp menu button
	 * @param {jQUery} $e - 対象となる jQuery オブジェクト
	 */
	var Accordion = function () {
		Accordion.Open();
	};

	// hover時に画像を切替える
	Accordion.Open = function(){
		$('.dl-accordion__title').click(function(){
			$(this).next('.dl-accordion__contents').slideToggle(500);
		});
	}

	/**
	 * @memberOf Accordion
	 * @const {string}
	 */
	Accordion._classname = 'Accordion';

	module.exports = Accordion;
})(jQuery);
